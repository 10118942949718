import { createSlice } from '@reduxjs/toolkit'
import {
  fetchHall,
  fetchHallsList,
  deleteHall,
  addHall,
  changeHall,
  AddPackageToHall,
  putHallBalance,
  takeHallBalance,
  resetHallMath,
  changeHallMath
} from '../services/Halls'
import { changeArr } from '../utils/changeArr'
import { paymentFormIsLoading, paymentFormSucces, paymentFormIsError } from './paymentReducer'
import {
  treeInfoSuccess,
  treeInfoIsLoading,
  treeInfoFailure,
  treeInfoChangeBalance,
} from './treeInfoReducer'
import { changeUserCurrency } from './authReducer'
import { IHall, isConfirmType, HallData, UserBalance, IPaymentData, ErrorType, IHallMathForm, IHallMath } from '../interfaces'
import { AppThunk } from './store'
import { removeCashbox, addCashbox } from '../services/Cashbox'
import { userAddCashbox, userDeleteCashbox } from '../redux/authReducer'

interface HallInitialState {
  data: IHall | null
  loading: boolean
  error: ErrorType[]
  halls: {
    data: IHall[]
    loading: boolean
    error: ErrorType[]
  }
  form: {
    loading: boolean
    error: ErrorType[]
  }
  math: {
    pageCount: number,
    data: any
    loading: boolean
    error: ErrorType[]
  }
  mathReset: {
    loading: boolean
    error: ErrorType[]
  }
}

export const initialState: HallInitialState = {
  data: null,
  loading: false,
  error: [],
  halls: {
    data: [],
    loading: false,
    error: [],
  },
  math: {
    pageCount: 0,
    data: null,
    loading: false,
    error: [],
  },
  mathReset: {
    loading: false,
    error: [],
  },
  form: {
    loading: false,
    error: [],
  },
}
export const slice = createSlice({
  name: 'hall',
  initialState,
  reducers: {
    hallIsLoading: (state) => {
      state.loading = true
    },
    hallSuccess: (state, { payload }) => {
      state.loading = false
      state.error = []
      state.data = payload
    },
    hallFailure: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    hallDataChangeBalance: (state, { payload }) => {
      if (state.data) {
        state.data = {
          ...state.data,
          balance: payload,
        }
      }
    },
    hallsListIsLoading: (state) => {
      state.halls.loading = true
    },
    hallsListSuccess: (state, { payload }) => {
      state.halls.loading = false
      state.halls.error = []
      state.halls.data = payload
    },
    hallsListFailure: (state, { payload }) => {
      state.halls.loading = false
      state.halls.error = payload
    },
    hallsListChange: (state, { payload }) => {
      state.halls.data = [...changeArr(state.halls.data, payload.id, payload)]
    },
    hallsListDeleteItem: (state, { payload }) => {
      state.halls.data = [...state.halls.data.filter((i) => i.id !== payload)]
    },
    hallsListItemAdd: (state, { payload }) => {
      state.halls.data = [...state.halls.data, payload]
    },
    hallChangeBalance: (state, { payload }) => {
      const hallsList: IHall[] = state?.halls?.data
      const id = hallsList.findIndex((i) => i.id === payload.id)
      const balance = typeof payload.balance === "object" ? payload.balance[Object.keys(payload.balance)[0]] : payload.balance;
      // const currency = hallsList[id].currency_id
      state.halls.data = [
        ...hallsList.slice(0, id),
        {
          ...hallsList[id],
          balance,
        },
        ...hallsList.slice(id + 1, hallsList.length),
      ]
    },
    changeHallBalanceValue: (state, { payload }) => {
      const array = state.halls.data
      const id = array.findIndex((i) => i.id === payload.id)
      state.halls.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          balance: array[id].balance + payload.balance,
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    addHallCashbox: (state, { payload }) => {
      const array = state.halls.data
      const id = array.findIndex((i) => i.id === payload.hall_id)
      state.halls.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          cashbox: [...array[id].cashbox, payload.cashbox],
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    deleteHallCashbox: (state, { payload }) => {
      const array = state.halls.data
      const id = array.findIndex((i) => i.id === payload.hall_id)
      state.halls.data = [
        ...array.slice(0, id),
        {
          ...array[id],
          cashbox: [
            ...array[id].cashbox.filter(
              (i: any) => i.hall_id !== payload.hall_id || i.user_id !== payload.user_id
            ),
          ],
        },
        ...array.slice(id + 1, array.length),
      ]
    },
    formIsLoading: (state) => {
      state.form.loading = true
    },
    formSucces: (state) => {
      state.form.loading = false
      state.form.error = []
    },
    formIsError: (state, { payload }) => {
      state.form.loading = false
      state.form.error = payload
    },
    mathIsLoading: (state) => {
      state.math.loading = true
    },
    mathSucces: (state, { payload }) => {
      state.math.data = payload
      state.math.loading = false
      state.math.error = []
    },
    hallMathSuccess: (state, { payload }) => {
      console.log(state.math);
      // state.math.data = payload
      // state.math.loading = false
      // state.math.error = []
    },
    mathIsError: (state, { payload }) => {
      state.math.loading = false
      state.math.error = payload
    },
    setMathPageCount: (state, { payload }) => {
      state.math.pageCount = payload
    },
    mathResetIsLoading: (state) => {
      state.mathReset.loading = true
    },
    mathResetSucces: (state, { payload }) => {
      state.mathReset.loading = false
      state.mathReset.error = []
      if (state.data) {
        console.log(payload)
        state.data.in = payload['1']
        state.data.out = payload['2']
        state.data.profit = payload['3']
        state.data.bank = payload['4']
        state.data.fake = payload['5']
        state.data.possibleFake = payload['6']
      }
    },
    mathResetIsError: (state, { payload }) => {
      state.mathReset.loading = false
      state.mathReset.error = payload
    },
    setHallStatistics: (state, { payload }) => {
      if (state.data) {
        state.data.statistics = payload
      }
    },
  },
})

export const getHall = (id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(hallIsLoading())

    return fetchHall(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(hallSuccess(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(hallFailure([res.data.data]))
          } else {
            dispatch(hallFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(hallFailure(err)))
  }
}

let halls: IHall[] = []
export const getHallsList = (page: number, limit: number): AppThunk => {
  return async (dispatch) => {
    dispatch(hallsListIsLoading())
    return fetchHallsList(page, limit)
      .then((res) => {
        if (res.data.code === 200) {
          halls = [...halls, ...res.data.data]
          if (res.headers['x-pagination-current-page'] < res.headers['x-pagination-page-count']) {
            dispatch(getHallsList(Number(res.headers['x-pagination-current-page']) + 1, limit))
            dispatch(hallsListSuccess(halls))
          } else {
            dispatch(hallsListSuccess(halls))
            halls = []
          }
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(hallsListFailure([res.data.data]))
          } else {
            dispatch(hallsListFailure(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(hallsListFailure(err)))
  }
}

export const deleteHallListItem = (
  id: number,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return deleteHall(id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(hallsListDeleteItem(id))
          dispatch(formSucces())
          setIsConfirm({ open: false })
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerAddHall = (
  data: HallData,
  packages: any,
  math: IHallMathForm,
  hideForm: (hall_id: number) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addHall(data)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(hallsListItemAdd(res.data.data))
          hideForm(res.data.data.id)
          AddPackageToHall(packages, res.data.data.id).then((res) => {
            if (res.data.code !== 200) {
              dispatch(formIsError(res.data.data))
            }
          })
          dispatch(formSucces())
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handlerChangeHall = (
  data: HallData,
  packages: any,
  math: IHallMathForm,
  hideForm: () => void,
  hallId?: number,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading());
    const x: IHallMath = {payout: +math.payout, max_rtp: +math.max_rtp, min_rtp: +math.min_rtp, adaptive_param: math.adaptive_param, adaptive_type: math.adaptive_type, reels_path: math.reels_path, overdraft: +math.overdraft};
    changeHallMath(x, hallId!).then((res) => {
      if (res.data.code === 200) {
        console.log(res.data)
        dispatch(hallMathSuccess(res.data.data));
      }
    });
    return (
      hallId &&
      AddPackageToHall(packages, hallId).then((res) => {
        if ([200, 403].includes(res.data.code)) {
          info && dispatch(treeInfoIsLoading())
          return changeHall(data, hallId)
            .then((res) => {
              if (res.data.code === 200) {
                dispatch(hallsListChange(res.data.data))
                info && dispatch(treeInfoSuccess(res.data.data))
                dispatch(hallSuccess(res.data.data))
                hideForm()
                dispatch(formSucces())
              } else {
                info && dispatch(treeInfoFailure(''))
                if (typeof res.data.data.length !== 'number') {
                  dispatch(formIsError([res.data.data]))
                } else {
                  dispatch(formIsError(res.data.data))
                }
              }
            })
            .catch((err) => dispatch(formIsError(err)))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(formIsError([res.data.data]))
          } else {
            dispatch(formIsError(res.data.data))
          }
        }
      })
    )
  }
}

export const handleChangeHallStatus = (
  hall: IHall | undefined,
  setIsBlock: (isBlock: isConfirmType) => void,
  info?: boolean
): AppThunk => {
  const data: any = {
    id: hall?.id,
    name: hall?.name,
    owner_id: hall?.owner_id,
    company_id: hall?.company_id,
    city_id: hall?.city_id,
    currency_id: hall?.currency_id,
    address: hall?.address,
    out_percent: hall?.out_percent,
    fake_payout: hall?.fake_payout,
    bet_list: hall?.bet_list,
    bet_max: hall?.bet_max,
    bet_min: hall?.bet_min,
    status: !!hall?.status ? 0 : 10
  }
  return async (dispatch) => {
    dispatch(formIsLoading())
    return (
      hall &&
      changeHall(data, hall.id)
        .then((res) => {
          if (res.data.code === 200) {
            info && dispatch(treeInfoSuccess(res.data.data))
            dispatch(hallsListChange(res.data.data))
            dispatch(formSucces())
            setIsBlock({ open: false })
          } else {
            info && dispatch(treeInfoFailure(res.data.data))
            if (typeof res.data.data.length !== 'number') {
              dispatch(formIsError([res.data.data]))
            } else {
              dispatch(formIsError(res.data.data))
            }
          }
        })
        .catch((err) => dispatch(formIsError(err)))
    )
  }
}

const getBalance = (balance: UserBalance) => {
  for (var i in balance) {
    return balance[i]
  }
}

export const handlerPutHallBalance = (
  data: IPaymentData,
  hallId: number,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return putHallBalance(data, hallId)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(getBalance(res.data.data.balance)))
          dispatch(
            hallChangeBalance({
              id: Number(res.data.data.id),
              balance: res.data.data.balance,
            })
          )
          dispatch(
            changeUserCurrency({
              currency: data.currency_id,
              balance: -data.amount,
            })
          )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handlerTakeHallBalance = (
  data: IPaymentData,
  hallId: number,
  info?: boolean
): AppThunk => {
  return async (dispatch) => {
    dispatch(paymentFormIsLoading())
    return takeHallBalance(data, hallId)
      .then((res) => {
        if (res.data.code === 200) {
          info && dispatch(treeInfoChangeBalance(getBalance(res.data.data.balance)))
          dispatch(
            hallChangeBalance({
              id: Number(res.data.data.id),
              balance: res.data.data.balance,
            })
          )
          dispatch(
            changeUserCurrency({
              currency: data.currency_id,
              balance: data.amount,
            })
          )
          dispatch(paymentFormSucces(res.data.data))
        } else {
          if (typeof res.data.data.length !== 'number') {
            dispatch(paymentFormIsError([res.data.data]))
          } else {
            dispatch(paymentFormIsError(res.data.data))
          }
        }
      })
      .catch((err) => dispatch(paymentFormIsError(err)))
  }
}

export const handleBindingToHall = (user_id: number, hall_id: number): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return addCashbox(user_id, hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          dispatch(
            addHallCashbox({
              hall_id: hall_id,
              cashbox: res.data.data,
            })
          )
          dispatch(
            userAddCashbox({
              user_id: user_id,
              cashbox: res.data.data,
            })
          )
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleRemoveToHall = (
  user_id: number,
  hall_id: number,
  setIsConfirm: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(formIsLoading())
    return removeCashbox(user_id, hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(formSucces())
          dispatch(
            deleteHallCashbox({
              hall_id: hall_id,
              user_id: user_id,
            })
          )
          dispatch(
            userDeleteCashbox({
              hall_id: hall_id,
              user_id: user_id,
            })
          )
          setIsConfirm({ open: false })
        } else {
          dispatch(formIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(formIsError(err)))
  }
}

export const handleResetMath = (
  hall_id: number,
  hideModal: (isConfirm: isConfirmType) => void
): AppThunk => {
  return async (dispatch) => {
    dispatch(mathResetIsLoading())
    return resetHallMath(hall_id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(mathResetSucces(res.data.data))
          hideModal({ open: false })
        } else {
          dispatch(mathResetIsError(res.data.data))
        }
      })
      .catch((err) => dispatch(mathResetIsError(err)))
  }
}

export const {
  hallIsLoading,
  hallSuccess,
  hallFailure,
  hallDataChangeBalance,
  hallsListIsLoading,
  hallsListSuccess,
  hallsListFailure,
  hallsListItemAdd,
  hallsListChange,
  hallsListDeleteItem,
  hallChangeBalance,
  changeHallBalanceValue,
  addHallCashbox,
  deleteHallCashbox,
  mathIsLoading,
  mathSucces,
  mathIsError,
  setMathPageCount,
  mathResetIsLoading,
  mathResetSucces,
  mathResetIsError,
  formIsLoading,
  formSucces,
  formIsError,
  hallMathSuccess,
  setHallStatistics
} = slice.actions

interface IState {
  hall: HallInitialState
}

export const hallSelector = (state: IState) => state.hall

export default slice.reducer
