import React from 'react';
import { ListWrapper } from './styles';
import { Wrapper } from '../../Operations/styles';

import { useDispatch, useSelector } from 'react-redux';
import {
  tableSelector,
  deleteTableListItem,
  handleChangeTableStatus,
  handlerTakeTableBalance,
  handlerPutTableBalance,
  fetchTableSessions,
  fetchTableSpins,
  handlerAddTable,
  closeTableSession,
  getAlltables,
  handleTableReload,
  handleTableHome,
} from '../../../redux/tableReducer';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Confirm from '../../Common/Confirm';
import Overlay from '../../Common/Overlay/index';
import { ITable, isConfirmType, IPaymentData, TableData } from '../../../interfaces';
import { useShowBlock } from '../../../hooks/useShowBlock';
import { hallSelector } from '../../../redux/hallReducer';
import Table from '../View';
import AddTable from '../Add';
import PaymentForm from '../../Payment';
import SessionsList from '../../ApplicationSessions/List';
import { prettyNum } from '../../../utils/prettyNum';
import SpinsList from '../../Spins/List';
import { findPermissionOr } from '../../../utils/find';
import { authSelector } from '../../../redux/authReducer';
import Highscore from '../Highscore';
import Pollscore from '../Pollscore';
import Operations from '../../Operations';
import { useHistory, useLocation } from 'react-router-dom';
import Spin from '../../Spins/View';
import { useTranslation } from 'react-i18next';
import Bind from '../Bind';
import { getPackageList, packageSelector } from '../../../redux/packageReducer';
import TableContent from './table-content';
import SearchHeader from './search-header';
import Header from './header';
import Title from './title';

interface TablesListProps {
  hall_id?: number
  isModal?: boolean
  height?: string
}

const TablesList: React.FC<TablesListProps> = ({ hall_id, isModal = false, height = '45vh' }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [t] = useTranslation()
  React.useEffect(() => { })
  const {
    tables: { data: tables, loading: tablesLoading },
    sessions: { data: sessions },
    spins: { data: spins, loading: spinsLoading },
    form: formLoading
  } = useSelector(tableSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)
  const {
    assigments: { items: assigments },
    role: { item: role }
  } = useSelector(authSelector)

  const {
    packages: { items: packages },
  } = useSelector(packageSelector)


  React.useEffect(() => {
    !tablesLoading && !tables.length && dispatch(getAlltables(1, 50))
    // eslint-disable-next-line
  }, [status, dispatch])

  React.useEffect(() => {
    !packages.length && dispatch(getPackageList())
    // eslint-disable-next-line
  }, [status, dispatch])

  const [selectedSort, setSelectedSort] = React.useState<string>('id')
  const [sortDir, setSortDir] = React.useState<boolean>(true)

  const [hallsGroup, setHallsGroup] = React.useState<number>(1)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })
  const [isBlock, setIsBlock] = React.useState<isConfirmType>({ open: false })

  const [isReload, setIsReload] = React.useState<isConfirmType>({ open: false })
  const [isHome, setIsHome] = React.useState<isConfirmType>({ open: false })

  const [isSession, setIsSession] = React.useState<isConfirmType>({ open: false })
  const [tableId, setTableId] = React.useState<null | number>(null)
  // const [sessionId, setSessionId] = React.useState<number>(-1)

  const [isChangeMode, setIsChangeMode] = React.useState(false)

  React.useEffect(() => {
    hall_id && setHallsGroup(0)
  }, [hall_id])

  const { showBlock: showTable, hideBlock: hideTable } = useShowBlock()

  const {
    showBlock: showAddTable,
    isBlockShown: isAddTable,
    hideBlock: hideAddTable,
  } = useShowBlock()

  const {
    showBlock: showChangeTable,
    // isBlockShown: isChangeTable,
    // hideBlock: hideChangeTable,
  } = useShowBlock()

  const {
    showBlock: showTableSessions,
    // isBlockShown: isTableSessions,
    // hideBlock: hideTableSessions,
  } = useShowBlock()

  const { showBlock: showPayment, isBlockShown: isPayment, hideBlock: hidePayment } = useShowBlock()

  const {
    showBlock: showHighscore,
    isBlockShown: isHighscore,
    hideBlock: hideHighscore,
  } = useShowBlock()

  const {
    showBlock: showPollscore,
    isBlockShown: isPollscore,
    hideBlock: hidePollscore,
  } = useShowBlock()

  const {
    showBlock: showOperations,
    // isBlockShown: isOperations,
    // hideBlock: hideOperations,
  } = useShowBlock()

  const { showBlock: showBind, isBlockShown: isBind, hideBlock: hideBind } = useShowBlock()

  const hallTables: ITable[] = hall_id ? tables.filter((i) => i.hall_id === hall_id) : tables

  const [search, setSearch] = React.useState<any>(null)
  const filteredTables: ITable[] = (() => {
    if (search) {
      return hallTables.filter((table) => {
        const idFilter = search?.id ? table.id === Number(search?.id) : true
        const nameFilter = !!search?.name
          ? table.name?.toLowerCase().indexOf(search.name.toLowerCase()) > -1 || table.phone?.indexOf(search.name) > -1 || table.email?.indexOf(search.name) > -1
          : true
        const loginFilter = !!search?.login
          ? table.login.toLowerCase().indexOf(search.login.toLowerCase()) > -1
          : true
        return idFilter && nameFilter && loginFilter
      })
    } else {
      return hallTables
    }
  })();

  const table = tables?.find((i: ITable) => i.id === tableId);
  const hall = halls.find((h) => h.id === table?.hall_id);
  const tableCurrency = hall?.currency_id;
  const tableBalance = role === 'cashier' && hall?.is_social_poll ? -1 : table?.balance && tableCurrency && table?.balance[String(tableCurrency)];

  const spinHeaderItemStyle: any = { textAlign: 'center', fontSize: '67%', padding: '5px' }

  const location: any = useLocation()
  const id = new URLSearchParams(location.search).get('table_id')
  const sessions_page = new URLSearchParams(location.search).get('sessions_page')
  const operations_page = Number(new URLSearchParams(location.search).get('operations_page'))
  const session_id = new URLSearchParams(location.search).get('session_id')
  const spin_id = new URLSearchParams(location.search).get('spin_id')
  // const spins_page = new URLSearchParams(location.search).get('spins_page')
  const session = sessions?.find((i) => i.id === Number(session_id))
  React.useEffect(() => {
    if (!!id) {
      setTableId(Number(id))
    } else {
      setTableId(null)
    }
    // eslint-disable-next-line
  }, [id])
  const ownerBalance: any = !!table && hall?.balance;
  /*const renderList = React.useCallback(() => {
    return hallsGroup ? renderGroup : renderAll
  }, [renderGroup, hallsGroup, renderAll])*/
  const canCreateTable = findPermissionOr(assigments, ['createGamblingTable', 'createGamblingTableChildren']);

  return (
    <ListWrapper isModal={isModal}>
      <Box component="div" style={{ minWidth: '900px' }}>
        {!isModal && <Title hallsGroup={hallsGroup} setHallsGroup={setHallsGroup} canCreate={canCreateTable} showAddTable={showAddTable} />}
        <Header isModal={isModal} selectedSort={selectedSort} sortDir={sortDir} setSelectedSort={setSelectedSort} setSortDir={setSortDir} />
        <SearchHeader isModal={isModal} search={search} setSearch={setSearch} />
        <TableContent preloader={tablesLoading && !tables.length} tables={filteredTables} halls={halls}
          showTable={showTable} showChangeTable={showChangeTable} setTableId={setTableId} setIsConfirm={setIsConfirm}
          setIsBlock={setIsBlock} setIsSession={setIsSession} setIsReload={setIsReload} setIsHome={setIsHome}
          showPayment={showPayment} showHighscore={showHighscore} showPollscore={showPollscore}
          showBind={showBind} setIsChangeMode={setIsChangeMode} isModal={isModal} hallsGroup={hallsGroup}
        />
      </Box>
      {!!isAddTable && (
        <Overlay>
          <AddTable
            hideAddTable={hideAddTable}
            handlerAddTable={(data: TableData, packages: string[], hideForm: () => void) =>
              dispatch(handlerAddTable(data, packages, hideForm))
            }
            halls={halls}
          />
        </Overlay>
      )}
      {!!id && !!tableId && (
        <Overlay>
          <Table
            hideTable={hideTable}
            tableId={tableId}
            showTableSessions={showTableSessions}
            showOperations={showOperations}
            isChangeMode={isChangeMode}
            setIsChangeMode={setIsChangeMode}
            showPayment={showPayment}
          />
        </Overlay>
      )}
      {!!isPayment && (
        <Overlay>
          <PaymentForm
            id={filteredTables.find((i: ITable) => i.id === tableId)}
            hidePayment={hidePayment}
            take={(data: IPaymentData, table: ITable) =>
              dispatch(handlerTakeTableBalance(data, table))
            }
            putEvent={(data: IPaymentData, table: ITable) =>
              dispatch(handlerPutTableBalance(data, table))
            }
            currency_id={tableCurrency}
            balance={tableBalance}
            ownerBalance={ownerBalance}
            info={false}
            hallLanguage={hall?.language}
          />
        </Overlay>
      )}
      {!!id && !!sessions_page && (
        <Overlay>
          <Wrapper>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <h3>{t('ru.sessions.title')}</h3>
              <IconButton
                onClick={() => {
                  const query = new URLSearchParams(location.search)
                  query.delete('sessions_page')
                  history.push({
                    pathname: location.pathname,
                    search: query.toString(),
                    state: {
                      prevPaths:
                        location.state && location.state.prevPaths ? location.state.prevPaths : [],
                    },
                  })
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <SessionsList
              table_id={Number(id)}
              title={''}
              scroll
              striped
              fetch={(id: number, page: number, limit: number) =>
                dispatch(fetchTableSessions(id, page, limit))
              }
              itemOnClick={(id: number) => {
                const query = new URLSearchParams(location.search)

                query.set('session_id', String(id))
                query.set('spins_page', String(1))
                history.push({
                  pathname: location.pathname,
                  search: query.toString(),
                  state: {
                    prevPaths:
                      location.state && location.state.prevPaths ? location.state.prevPaths : [],
                  },
                })
              }}
            />
          </Wrapper>
        </Overlay>
      )}
      {!!id && !!session_id && (
        <Overlay>
          <Wrapper>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <Box display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.subtitle')} <br /># {session?.id}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.balance_start')}
                  <br /> {prettyNum(session?.start_balance)}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.balance_end')}
                  <br /> {prettyNum(session?.balance)}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.spins_count')}
                  <br /> {session?.count_spins}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.denomination')}
                  <br /> {session?.denomination}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.bet')}
                  <br /> {prettyNum(session?.bet)}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.win')}
                  <br /> {prettyNum(session?.win)}
                </span>
                <span style={spinHeaderItemStyle}>
                  {t('ru.sessions.fields.input')}
                  <br /> {prettyNum(session?.input)}
                </span>
              </Box>
              <IconButton
                onClick={() => {
                  const query = new URLSearchParams(location.search)
                  query.delete('session_id')
                  query.delete('spins_page')
                  history.push({
                    pathname: location.pathname,
                    search: query.toString(),
                    state: {
                      prevPaths:
                        location.state && location.state.prevPaths ? location.state.prevPaths : [],
                    },
                  })
                  // history.push('/' + location.pathname + query.toString(), {
                  //   prevPaths: location.state && ,
                  // })
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <SpinsList
              session_id={Number(session_id)}
              spins={spins}
              loading={spinsLoading}
              fetch={(id: number, page: number, limit: number) =>
                dispatch(fetchTableSpins(id, page, limit))
              }
            />
          </Wrapper>
        </Overlay>
      )}
      {!!id && !!spin_id && (
        <Overlay>
          <Spin
            spinId={Number(spin_id)}
            hide={() => {
              const query = new URLSearchParams(location.search)
              query.delete('spin_id')
              history.push({
                pathname: location.pathname,
                search: query.toString(),
                state: {
                  prevPaths:
                    location.state && location.state.prevPaths ? location.state.prevPaths : [],
                },
              })
            }}
          />
        </Overlay>
      )}
      {!!id && !!operations_page && (
        <Overlay>
          <Operations
            table_id={Number(id)}
            hideOperations={() => {
              const query = new URLSearchParams(location.search)
              query.delete('operations_page')
              history.push({
                pathname: location.pathname,
                search: query.toString(),
                state: {
                  prevPaths:
                    location.state && location.state.prevPaths ? location.state.prevPaths : [],
                },
              })
            }}
          />
        </Overlay>
      )}
      {!!isHighscore && (
        <Overlay>
          <Highscore
            id={tableId}
            currency_id={
              halls.find((h) => h.id === filteredTables?.find((i) => i.id === tableId)?.hall_id)
                ?.currency_id
            }
            setTableId={setTableId}
            hideHighscore={hideHighscore}
            highscore={filteredTables.find((i) => i.id === tableId)?.highscore}
          />
        </Overlay>
      )}
      {!!isPollscore && (
        <Overlay>
          <Pollscore
            id={tableId}
            currency_id={tableCurrency}
            setTableId={setTableId}
            hidePollscore={hidePollscore}
            pollscore={filteredTables.find((i) => i.id === tableId)?.is_out && tableCurrency ? filteredTables.find((i) => i.id === tableId)?.balance[tableCurrency] : filteredTables.find((i) => i.id === tableId)?.pollscore}
          />
        </Overlay>
      )}
      {!!isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deleteTableListItem(isConfirm.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {!!isBlock.open && (
        <Overlay>
          <Confirm
            text={`${!!filteredTables && !!filteredTables.find((i) => i.id === isBlock.id)?.status
              ? t('ru.common.confirm.block')
              : t('ru.common.confirm.unblock')
              } ${isBlock.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsBlock({ open: false })
            }}
            submit={() => {
              dispatch(
                handleChangeTableStatus(
                  filteredTables.find((i) => i.id === isBlock.id),
                  setIsBlock
                )
              )
            }}
          />
        </Overlay>
      )}
      {!!isSession.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.session_close')} ${isSession.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsSession({ open: false })
            }}
            submit={() => {
              isSession.id && dispatch(closeTableSession(isSession.id, setIsSession))
            }}
          />
        </Overlay>
      )}
      {isBind && tableId && (
        <Overlay>
          <Bind hideBind={hideBind} table_id={tableId} />
        </Overlay>
      )}
      {isReload?.open && (
        <Overlay>
          <Confirm
            text={`Пререзагрузить стол?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsReload({ open: false })
            }}
            submit={() => {
              isReload.id && dispatch(handleTableReload(isReload.id, setIsReload))
            }}
          />
        </Overlay>
      )}
      {isHome?.open && (
        <Overlay>
          <Confirm
            text={`Перенаправить на главную?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsHome({ open: false })
            }}
            submit={() => {
              isHome.id && dispatch(handleTableHome(isHome.id, setIsHome))
            }}
          />
        </Overlay>
      )}
    </ListWrapper>
  )
}

export default TablesList
