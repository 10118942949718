import React from 'react';
import { ListItem } from '../styles';

import CurrentGameBtn from './current-game-btn';
import BlockedIcon from './blocked-icon';
import BigScreenButtons from './big-screen-buttons';
import SmallScreenButtons from './small-screen-buttons';

import { makeStyles } from '@material-ui/core';
import { prettyNum } from '../../../../utils/prettyNum';
import { isConfirmType, UserBalance, CurrentGameType } from '../../../../interfaces';
import { useTranslation } from 'react-i18next';
import { findPermissionOr } from '../../../../utils/find';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../redux/authReducer';

const useStyles = makeStyles(() => ({
  balance: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#919191',
    padding: '3px',
    fontWeight: 'bold',
    fontSize: '1.1em'
  }
}));

interface TablesListItemProps {
  id: number
  name: string
  currency_id: string | null
  login: string
  denomination: number
  highscore: number
  pollscore: number
  is_out?: number
  balance: UserBalance
  currentGame: CurrentGameType | null
  online: number
  connection_time: string
  status: boolean
  playerConnection: any
  email: string | null;
  phone: string | null;
  showTable: () => void
  showChangeTable: () => void
  setTableId: (id: number) => void
  setIsConfirm: (isConfirm: isConfirmType) => void
  setIsBlock: (isConfirm: isConfirmType) => void
  setIsSession: (isConfirm: isConfirmType) => void
  setIsReload: (isConfirm: isConfirmType) => void
  setIsHome: (isConfirm: isConfirmType) => void
  showPayment: () => void
  showHighscore: () => void
  showPollscore: () => void
  showBind: () => void
  setIsChangeMode: (isChangeMode: boolean) => void
  isModal: boolean,
  payout?: any;
  mathType?: number;
  setMathType: (mathType: number) => void;
}

function cmp(prevProps: TablesListItemProps, nextProps: TablesListItemProps) {
  const isPlayerConnection = (prevProps.playerConnection === null && nextProps.playerConnection === null) || (prevProps.playerConnection !== null && nextProps.playerConnection !== null && prevProps.playerConnection.player_id === nextProps.playerConnection.player_id);
  const isGame = (prevProps.currentGame === null && nextProps.currentGame === null) || (prevProps.currentGame !== null && nextProps.currentGame !== null && prevProps.currentGame.code === nextProps.currentGame.code);
  return isGame && isPlayerConnection && prevProps.id === nextProps.id && prevProps.highscore === nextProps.highscore &&
    prevProps.pollscore === nextProps.pollscore && prevProps.is_out === nextProps.is_out && prevProps.balance === nextProps.balance &&
    prevProps.status === nextProps.status && prevProps.online === nextProps.online && prevProps.mathType === nextProps.mathType;
};

const Item: React.FC<TablesListItemProps> = React.memo(
  ({ id, name, currency_id, login, denomination, highscore, pollscore, is_out = 0, balance,
    currentGame, online, connection_time, status, playerConnection, showTable, showChangeTable,
    setTableId, setIsConfirm, setIsBlock, setIsSession, setIsChangeMode, showPayment, setIsReload,
    setIsHome, showHighscore, showPollscore, showBind, isModal, payout, mathType = 0, setMathType, email, phone }) => {
    const classes = useStyles();
    const [t] = useTranslation();

    const { assigments: { items } } = useSelector(authSelector);
    const table_balance: any = balance && currency_id ? prettyNum(balance[currency_id]) : '0.00';
    const isOut = pollscore > 0 || (balance && currency_id && balance[currency_id] > 0 && is_out > 0);
    return (
      <div>
        <ListItem bg={'#fff'} isModal={isModal}>
          <div>{id}</div>
          <div>{login}</div>
          <div>{name || phone || email}</div>
          <div>{denomination / 100}</div>
          <div onClick={() => { setTableId(id); showPayment(); }} >
            <span className={classes.balance}>{table_balance ? table_balance : '-'}</span>
          </div>
          <div onClick={() => { setTableId(id); showHighscore(); }} >
            <span className={classes.balance}>{prettyNum(highscore)}</span>
          </div>
          <div onClick={() => { if (isOut) { setTableId(id); showPollscore(); } }} >
            {isOut &&
              <span className={classes.balance} style={{ background: '#03ccdd', textAlign: 'center', fontSize: '75%' }} >
                {t('ru.tables.fields.out_sum')}
              </span>}
          </div>
          <div>{!!payout ? (payout['in'] > 0 ? (100 * payout['out'] / payout['in']).toFixed(0) : '∞') : ''}</div>
          {findPermissionOr(items, ['viewTableMath', 'viewTableMathChildren']) && <div>
            <select disabled={!findPermissionOr(items, ['editTableMath', 'editTableMathChildren'])} value={mathType} onChange={(e: any) => setMathType(+e.target.value)}>
              <option value="0">standart</option>
              <option value="1">bad</option>
              <option value="2">good</option>
            </select>
          </div>}
          <div>
            {status ? <CurrentGameBtn currentGame={currentGame} online={online} /> : <BlockedIcon />}
          </div>
          <div>
            {window.innerWidth < 1200 ?
              <SmallScreenButtons id={id} name={name} isModal={isModal} status={status} playerConnection={playerConnection} currentGame={currentGame}
                setIsChangeMode={setIsChangeMode} setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsSession={setIsSession}
                showBind={showBind} setTableId={setTableId} setIsHome={setIsHome} setIsReload={setIsReload} />
              : <BigScreenButtons id={id} name={name} isModal={isModal} status={status} playerConnection={playerConnection} currentGame={currentGame}
                setIsChangeMode={setIsChangeMode} setIsConfirm={setIsConfirm} setIsBlock={setIsBlock} setIsSession={setIsSession}
                showBind={showBind} setTableId={setTableId} setIsHome={setIsHome} setIsReload={setIsReload} />
            }
          </div>
        </ListItem>
      </div>
    )
  }, cmp);

export default Item;
