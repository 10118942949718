import axios from 'axios'
import { HallData, IPaymentData } from '../interfaces'

export const fetchHallsList = async (page: number, limit: number) => {
  return axios.get(`hall`, {
    params: {
      page: page,
      'per-page': limit,
      expand: 'currency,owner,denomination,cashbox,real_month_payout',
    },
  })
}

export const addHall = async (data: HallData) => {
  return axios.post('hall', data, {
    params: {
      expand: 'company,city,currency,packages,owner,denomination,denominations,jackpots,cashbox,registration_code',
    },
  })
}

export const fetchHall = async (id: number) => {
  return axios.get(`hall/${id}`, {
    params: {
      expand: 'company,city,currency,packages,owner,denomination,denominations,jackpots,cashbox,real_month_payout,registration_code',
    },
  })
}

export const changeHall = async (data: HallData, id: number) => {
  return axios.put(`hall/${id}`, data, {
    params: {
      expand: 'company,city,currency,packages,owner,denomination,denominations,jackpots,cashbox,real_month_payout,registration_code',
    },
  })
}

export const deleteHall = async (id: number) => {
  return axios.delete(`hall/${id}`)
}

export const putHallBalance = async (data: IPaymentData, id: number) => {
  return axios.post(`hall/${id}/balance/put`, data)
}

export const takeHallBalance = async (data: IPaymentData, id: number) => {
  return axios.post(`hall/${id}/balance/take`, data)
}

export const AddPackageToHall = async (data: HallData, hall_id: number) => {
  return axios.put(`hall/${hall_id}/packages`, data, {
    params: { expand: 'packages,owner,' },
  })
}

export const changePackageToHall = async (data: HallData, hall_id: number) => {
  return axios.put(`hall/${hall_id}/packages`, data)
}

export const deletePackageToHall = async (hall_id: number) => {
  return axios.delete(`hall/${hall_id}/packages`)
}

export const getHallsOperations = async (hall_id: number, page: number, limit: number) => {
  return axios.get(`hall/${hall_id}/balance/operations`, {
    params: {
      page: page,
      'per-page': limit,
    },
  })
}

export const resetHallMath = async (hall_id: number) => {
  return axios.post(`hall/${hall_id}/math/reset`)
}

export const getHallMathChanges = async (data: any, hall_id: number) => {
  return axios.post(`hall/${hall_id}/math`, data)
}

export const loadHallStatistics = async (type: number, hall_id: number) => {
  return axios.get(`hall/${hall_id}/math/statistics/${type}`)
}

export const distributionOfDepositsAndPayments = async (hall_id: number, table_id: number) => {
  return axios.post(`hall/${hall_id}/math/distribution/${table_id}`)
}

export const changeHallMath = async (data: any, hall_id: number) => {
  return axios.post(`hall/save-math/${hall_id}`, { math: data });
}
// POST v1/hall/<id:\d+>/bank/report
// История операций по банку зала

// POST v1/hall/<hallId:\d+>/math/distribution/<gamblingTableId:\d+>
// Распределение пополнений и выплат в столе/зале
// Типы операций:
// public const TYPE_IN = 1; // Пополнение
// public const TYPE_OUT = 2; // Снятие
// public const TYPE_SAVE_HIGHSCORE = 3; // Сохранение рекорда
// public const TYPE_OPEN_SESSION = 4; // Пополнение игровой сессии
// public const TYPE_CLOSE_SESSION = 5; // Выход из игры
// public const TYPE_RESET = 6; // Сброс
// public const TYPE_CORRECTION = 7; // Корректировка
