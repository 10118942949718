import React, { FunctionComponent } from 'react';
import { Grid, TextField } from '@material-ui/core';

type FieldRegistrationCodeProps = {
    className: string;
    label: string;
    value: string;
    helperText: string;
    error: boolean;
    onChange: (obj: any) => void;
};

function cmp(prev: FieldRegistrationCodeProps, cur: FieldRegistrationCodeProps): boolean {
    return prev.className === cur.className && prev.label === cur.label && prev.value === cur.value && prev.helperText === cur.helperText && prev.error === cur.error;
}

const FieldRegistrationCode: FunctionComponent<FieldRegistrationCodeProps> = React.memo(({ className, label, value, onChange, error, helperText }) => {
    return <Grid item xs={12} sm={8}>
        <TextField className={className} label={label} type="text" value={value} name="registration_code" onChange={onChange} size="small" error={error} helperText={helperText} />
    </Grid>;
}, cmp);

export default FieldRegistrationCode;