import React, { FunctionComponent, useEffect } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ITable, IHall, isConfirmType } from '../../../interfaces';
import Item from './Item';
import EmptyTable from './EmptyTable';
import HallBox from './hall-box';
import { getTablesList, handlerSetMathType } from '../../../redux/tableReducer';
import { useDispatch } from 'react-redux';

type TableContentGroupProps = {
    tables: ITable[],
    halls: IHall[],
    showTable: () => void
    showChangeTable: () => void
    setTableId: (id: number) => void
    setIsConfirm: (isConfirm: isConfirmType) => void
    setIsBlock: (isConfirm: isConfirmType) => void
    setIsSession: (isConfirm: isConfirmType) => void
    setIsReload: (isConfirm: isConfirmType) => void
    setIsHome: (isConfirm: isConfirmType) => void
    showPayment: () => void
    showHighscore: () => void
    showPollscore: () => void
    showBind: () => void
    setIsChangeMode: (isChangeMode: boolean) => void
    isModal: boolean
    mathType?: number;
};

let hInterval: ReturnType<typeof setInterval>;
let currentHall: number;

const TableContentGroup: FunctionComponent<TableContentGroupProps> = ({ tables, halls, setTableId,
    showTable, showChangeTable, setIsConfirm, setIsBlock, setIsSession, setIsReload, setIsHome, showPayment,
    showHighscore, showPollscore, showBind, setIsChangeMode, isModal }) => {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState<number>(1);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [hallId, setHallId] = React.useState<number>((halls.length && halls[0].id) || 0);
    const hallTables = tables.filter(table => table.hall_id === hallId);
    const lastPage = Math.ceil(hallTables.length / pageSize);
    useEffect(() => {
        return () => {
            currentHall = 0;
        }
    }, []);
    if (!hallId && halls.length) {
        setHallId(halls[0].id);
    }
    if (hallId) {
        currentHall = hallId;
    }
    if (!hInterval) {
        hInterval = setInterval(() => {
            currentHall && dispatch(getTablesList({ hall_id: '' + currentHall }));
        }, 30000);
    }
    const hall = halls.find(table => table.id === hallId);
    if (!hallTables || !hall) {
        return <EmptyTable />;
    }
    return <div style={{ position: 'relative' }}>
        <ul>
            <HallBox hallId={hallId} setHallId={setHallId} halls={halls} />
            {hallTables.slice((page - 1) * pageSize, page * pageSize).map((item: ITable) => <div key={item.id}>
                <Item
                    id={item.id} name={item.name} currency_id={hall.currency_id || null} login={item.login} denomination={item.denomination}
                    highscore={item.highscore} pollscore={item.pollscore} balance={item.balance} currentGame={item.currentGame}
                    online={item.online} connection_time={item.connection_time} status={item.status} playerConnection={item.playerConnection}
                    phone={item.phone} email={item.email}
                    setTableId={setTableId} showTable={showTable} showChangeTable={showChangeTable} setIsConfirm={setIsConfirm}
                    setIsBlock={setIsBlock} setIsSession={setIsSession} setIsReload={setIsReload} setIsHome={setIsHome}
                    showPayment={showPayment} showHighscore={showHighscore} showPollscore={showPollscore} showBind={showBind}
                    setIsChangeMode={setIsChangeMode} isModal={isModal} payout={item.real_month_payout} is_out={item.is_out}
                    mathType={item.math_type} setMathType={(mathType) => {dispatch(handlerSetMathType(item.id, mathType));}}
                />
            </div>, hallTables)}
        </ul>
        {lastPage > 1 && <Pagination variant="outlined" shape="rounded" siblingCount={0} count={lastPage} page={page} onChange={(e, value) => setPage(value)} />}
        Записей на страницу: <Select value={pageSize} onChange={(e: any) => { setPage(1); setPageSize(e.target.value); }}>
            <MenuItem value="10">10</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
        </Select>
    </div>;
};

export default TableContentGroup;