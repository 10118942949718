import React, { ReactText } from 'react';
import { useForm } from '../../../hooks/useForm';
import { useShowBlock } from '../../../hooks/useShowBlock';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Select,
  Input,
  MenuItem,
  Collapse,
  ListItem,
  Chip,
  Checkbox,
  IconButton
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';

import { useSelector, useDispatch } from 'react-redux';
import { packageSelector } from '../../../redux/packageReducer';

import CurrencyForm from './Currency';
import CityForm from './City';
import CompanyForm from './Company';
import { FormWrapper, useStyles } from './styles';
import Overlay from '../../Common/Overlay/index';
import { formIsError, hallSelector } from '../../../redux/hallReducer';
import Preloader from '../../Common/Preloader';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';
import { IPackage, IGame, HallData, IHallMathForm } from '../../../interfaces';
import ErrorText from '../../Common/ErrorText';
import { TabItem } from '../View/styles';
import { prettyNum } from '../../../utils/prettyNum';
import JackpotsList from '../../Jackpots/List';
import TablesTab from '../View/Tables';
import Binding from '../View/Binding';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../../redux/authReducer';
import { HallFormProps } from './interface';
import Info from './tabs/info';
import Hotkeys from '../../Hotkeys';
import MathTab from './tabs/math';
import { findPermissionOr } from '../../../utils/find';
import GamePackages from '../View/game-packages';
import { defaultFace } from '../../../settings/hall';

const HallForm: React.FC<HallFormProps> = ({
  onSubmit,
  cancelBtnEvent,
  cancelBtnText,
  submitBtnText,
  user_id,
  id,
  name,
  address,
  city_id,
  limit_id,
  currency_id,
  company_id,
  owner_id,
  cashback,
  out_percent,
  poll_inc,
  packets,
  denomination,
  denominations,
  status,
  max_win_from_deposit = 100,
  max_win_per_spin = 10000,
  win_per_spin_percent_bet = 100,
  win_from_deposit_for_lose = 100,
  max_deposit_for_lose = 10000,
  min_cashback_deposit = 0,
  math_type = 4,
  hall_template = defaultFace,
  is_social_poll = 1, show_logo = 1, text = '', is_school, tab, setTab, change, hideForm,
  keyboard_type = 0, show_currency = 0, language = 'RUS', jackpot_migration = 0, math,
  reel_animation = 0, oferta: ofertaInit = 0, cashback_reel = 80, registration_code = "",
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t] = useTranslation();

  const { packages: { items: packages } } = useSelector(packageSelector);
  const { form: { error, loading: formLoading } } = useSelector(hallSelector);

  const { role: { item: userRole }, assigments: { items } } = useSelector(authSelector);

  const { form, handleInputChange, clearForm, setFormItem } = useForm({
    name: name,
    address: address ? address : '',
    status: status,
    denom: '',
    max_win_from_deposit: max_win_from_deposit ? max_win_from_deposit : 100,
    max_win_per_spin: prettyNum(max_win_per_spin) ? prettyNum(max_win_per_spin) : prettyNum(10000),
    win_per_spin_percent_bet: win_per_spin_percent_bet ? win_per_spin_percent_bet : 100,
    win_from_deposit_for_lose: win_from_deposit_for_lose ? win_from_deposit_for_lose : 100,
    max_deposit_for_lose: prettyNum(max_deposit_for_lose) ? prettyNum(max_deposit_for_lose) : prettyNum(10000),
    min_cashback_deposit: min_cashback_deposit ? min_cashback_deposit / 100 : 0,
    math_type: math_type, hall_template: hall_template, is_social_poll: is_social_poll,
    show_logo: show_logo, text: text, is_school: is_school, keyboard_type, show_currency,
    language, jackpot_migration, math, reel_animation, oferta: ofertaInit, cashback_reel,
    registration_code,
  });
  // selectors:
  const [user, setUser] = React.useState<number | undefined>(user_id ? user_id : owner_id);
  const [city, setCity] = React.useState<number | undefined>(city_id);
  const [limit, setLimit] = React.useState<number | null | undefined>(limit_id);
  const [currency, setCurrency] = React.useState<string | undefined>(currency_id);
  const [company, setCompany] = React.useState<number | undefined>(company_id);

  const [packagesSelected, setPackagesSelected] = React.useState<number[]>([]);
  const [gamesSelected, setGamesSelected] = React.useState<GamesType[]>([]);
  const [denominationsSelected, setDenominationsSelected] = React.useState<ReactText[]>([100]);
  const [defaultDenomination, setDefaultDenomination] = React.useState<number>(denomination || 100);
  const [selectedCashback, setSelectedCashback] = React.useState<number>(cashback || 0);
  const [outPercent, setOutPercent] = React.useState<number>(out_percent || 85);
  const [pollInc, setPollInc] = React.useState<number>(poll_inc || 10);
  const [oferta, setOferta] = React.useState<number>(ofertaInit);

  /*React.useEffect(() => {
    cashback && setSelectedCashback(cashback);
    out_percent && setOutPercent(out_percent);
  }, [cashback, out_percent]);*/

  const getGamesId = (games: IGame[]) => {
    let arr: number[] = [];
    games.forEach((item) => {
      arr = [...arr, item.id]
    });
    return arr;
  };

  interface GamesType {
    package_id: number;
    isOpen: boolean | undefined;
    games: number[];
  };

  React.useEffect(() => {
    let packages: number[] = [],
      games: GamesType[] = []
    packets &&
      packets.forEach((item) => {
        packages = [...packages, item.package.id]
        games = [
          ...games,
          {
            package_id: item.package.id,
            isOpen: false,
            games: getGamesId(item.games),
          },
        ]
      })
    packages && setPackagesSelected(packages);
    games && setGamesSelected(games);
    denomination && setDefaultDenomination(defaultDenomination);
    denominations && setDenominationsSelected(denominations);
    // eslint-disable-next-line
  }, [denomination]);

  const handlerOpenPackage = (arr: GamesType[], package_id: number) => {
    let itemId = arr.findIndex((i) => i.package_id === package_id)
    setGamesSelected([
      ...arr.slice(0, itemId),
      {
        package_id: arr[itemId].package_id,
        isOpen: !arr[itemId].isOpen,
        games: arr[itemId].games,
      },
      ...arr.slice(itemId + 1, arr.length),
    ])
  };

  const handlerCheckGame = (arr: GamesType[], package_id: number, game_id: number) => {
    let itemId = arr.findIndex((i: GamesType) => i.package_id === package_id)

    const changeGamesArray = (games: number[], game: number) => {
      if (games.indexOf(game) !== -1) {
        let index = games.indexOf(game)
        console.log(index, game_id)
        return [...games.slice(0, index), ...games.slice(index + 1, games.length)]
      } else {
        return [...games, game]
      }
    }
    setGamesSelected([
      ...arr.slice(0, itemId),
      {
        package_id: arr[itemId].package_id,
        isOpen: arr[itemId].isOpen,
        games: changeGamesArray(arr[itemId].games, game_id),
      },
      ...arr.slice(itemId + 1, arr.length),
    ])
  };
  // type PackageType = {
  //   id: number
  // }
  // interface packagesData {
  //   package: PackageType
  //   games: GamesType[]
  // }
  const getPackagesData = () => {
    let data: any = []

    const getGames = (item: number) => {
      let gamesId: number[] | undefined = []
      if (!!gamesSelected.find((i: GamesType) => i.package_id === item)) {
        gamesId = gamesSelected.find((i: GamesType) => i.package_id === item)?.games
      } else {
        gamesId = []
      }
      return gamesId
    }
    packagesSelected.forEach((item: number) => {
      data = [
        ...data,
        {
          package: {
            id: item,
          },
          games: getGames(item),
        },
      ]
    })
    return {
      packages: data,
    }
  };

  const data: HallData = {
    id: 0,
    name: form.name,
    company_id: company,
    city_id: city,
    limit_id: limit,
    currency_id: currency,
    cashback: selectedCashback,
    address: form.address,
    out_percent: outPercent,
    poll_inc: pollInc,
    bet_list: '',
    bet_max: 1,
    bet_min: 1,
    status: form.status ? 10 : 0,
    owner_id: user,
    denomination: defaultDenomination,
    denominations: denominationsSelected,
    max_win_from_deposit: form.max_win_from_deposit,
    max_win_per_spin: form.max_win_per_spin * 100,
    win_per_spin_percent_bet: form.win_per_spin_percent_bet,
    win_from_deposit_for_lose: form.win_from_deposit_for_lose,
    max_deposit_for_lose: form.max_deposit_for_lose * 100,
    min_cashback_deposit: form.min_cashback_deposit * 100,
    math_type: form.math_type,
    hall_template: form.hall_template,
    is_social_poll: form.is_social_poll,
    show_logo: form.show_logo,
    text: form.text,
    is_school: form.is_school,
    keyboard_type: form.keyboard_type || 0,
    show_currency: form.show_currency || 0,
    language: form.language || 'RUS',
    jackpot_migration: form.jackpot_migration || 0,
    math: form.math,
    reel_animation: form.reel_animation,
    oferta: form.oferta,
    cashback_reel: form.cashback_reel,
    registration_code: form.registration_code,
  };
  const tmp = form.math || { payout: "90", max_rtp: "92", min_rtp: "82", adaptive_type: 0, adaptive_param: 0, overdraft: "0", reels_path: "reels" };
  const [hallMathForm, setHallMathForm] = React.useState<IHallMathForm>({ ...tmp });

  const { showBlock: showCity, isBlockShown: isCity, hideBlock: hideCity } = useShowBlock();

  const { showBlock: showCurrency, isBlockShown: isCurrency, hideBlock: hideCurrency } = useShowBlock();

  const { showBlock: showCompany, isBlockShown: isCompany, hideBlock: hideCompany } = useShowBlock();

  React.useEffect(() => {
    return () => {
      clearForm();
      dispatch(formIsError(null));
    }
    // eslint-disable-next-line
  }, []);

  type KeyEventType = {
    code: string
  };

  const handleKeyDown = (e: KeyEventType) => {
    if (e.code === 'Escape') {
      hideForm()
    }
    if (e.code === 'Enter') {
      onSubmit(data, getPackagesData(), hallMathForm, hideForm, id)
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line
  }, []);

  const renderPackets = () => {
    return (
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth size="small">
          <FormHelperText>{t('ru.halls.fields.packets')}</FormHelperText>
          <Select multiple value={packagesSelected} input={<Input />}>
            {packages && packages.map((item: IPackage) => (
              <MenuItem
                key={item.id}
                value={item.id}
                onClick={() => {
                  if (packagesSelected.includes(item.id)) {
                    let index = packagesSelected.indexOf(item.id)
                    setPackagesSelected([
                      ...packagesSelected.slice(0, index),
                      ...packagesSelected.slice(index + 1, packagesSelected.length),
                    ])
                  } else {
                    setPackagesSelected([...packagesSelected, item.id])
                  }
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {packagesSelected.map((item) => {
            const packageItem = packages && packages.find((i: IPackage) => i.id === item)
            packageItem &&
              !gamesSelected.find((i: GamesType) => i.package_id === packageItem.id) &&
              setGamesSelected([
                ...gamesSelected,
                {
                  package_id: packageItem.id,
                  isOpen: false,
                  games: getGamesId(packageItem.games),
                },
              ])
            const param: GamesType | undefined =
              packageItem &&
              gamesSelected.find((i: GamesType) => i.package_id === packageItem.id) &&
              packageItem &&
              gamesSelected.find((i: GamesType) => i.package_id === packageItem.id)
            const param3 = param && param.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
            return (
              packageItem && (
                <React.Fragment key={item}>
                  <ListItem
                    button
                    onClick={() => handlerOpenPackage(gamesSelected, packageItem.id)}
                    className={classes.packet}
                  >
                    <Box display="flex" alignItems="center">
                      <BorderOuterIcon className={classes.icon} />
                      {packageItem.name}
                      {param3}
                    </Box>
                    <IconButton
                      onClick={() => {
                        let index = packagesSelected.indexOf(packageItem.id)
                        setPackagesSelected([
                          ...packagesSelected.slice(0, index),
                          ...packagesSelected.slice(index + 1, packagesSelected.length),
                        ])
                      }}
                      style={{ padding: 0 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItem>
                  <Collapse in={param && param.isOpen}>
                    <Box className={classes.chips}>
                      {packageItem.games.map((game: IGame) => {
                        return (
                          <Chip
                            className={classes.chip}
                            key={game.id}
                            onClick={() => handlerCheckGame(gamesSelected, packageItem.id, game.id)}
                            label={
                              <Box display="flex" justifyContent="space-between" alignItems="center">
                                <span>{game.name}</span>
                                <Checkbox
                                  checked={param && param.games.includes(game.id)}
                                  color="default"
                                />
                              </Box>
                            }
                          />
                        )
                      })}
                    </Box>
                  </Collapse>
                </React.Fragment>
              )
            )
          })}
        </FormControl>
      </Grid>
    )
  };

  const validation = form.max_win_from_deposit < 100 || form.max_win_per_spin < 100 || form.max_deposit_for_lose < 100 || form.win_from_deposit_for_lose < 100 || form.win_per_spin_percent_bet < 100;

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return <Info form={form} handleInputChange={handleInputChange} error={error} change={change || false} setFormItem={setFormItem}
          showCity={showCity} showCurrency={showCurrency} showCompany={showCompany} user_id={user_id || 0} setUser={setUser} setCompany={setCompany}
          ownerId={user || owner_id || 0} cityId={data.city_id || 0} setCity={setCity} limitId={data.limit_id || 0} setLimit={setLimit} currency={data.currency_id || ''} setCurrency={setCurrency} companyId={data.company_id || 0}
          outPercent={data.out_percent} setOutPercent={setOutPercent} pollInc={data.poll_inc!} setPollInc={setPollInc} selectedCashback={data.cashback} setSelectedCashback={setSelectedCashback}
          denominations={data.denominations} setDenominationsSelected={setDenominationsSelected} denomination={data.denomination || 100} setDefaultDenomination={setDefaultDenomination} oferta={oferta} setOferta={setOferta} />;
      case 1:
        return findPermissionOr(items, ['updateHallPackage', 'hallChildrenPackage']) ? renderPackets() : <GamePackages packages={packages.filter(p => packagesSelected.includes(p.id))} />;
      case 2:
        return id && <TablesTab hallId={id} change />;
      case 3:
        return <JackpotsList hall_id={id} change />;
      case 4:
        return id && <Binding hall_id={id} change />;
      case 6:
        return id && findPermissionOr(items, ['updateHallMath', 'updateChildrenHallMath']) && <MathTab hallMathForm={hallMathForm} setHallMathForm={setHallMathForm} />;
      case 7:
        return id && <Hotkeys hallId={id} />;
      default:
        setTab(4);
    }
  };

  return formLoading ? <Preloader absolute={false} /> : (
    <FormWrapper onSubmit={(e) => { e.preventDefault(); onSubmit(data, getPackagesData(), hallMathForm, hideForm, id); }} >
      {' '}
      <Box className="tabs" display="flex" justifyContent="flex-start" style={{ overflowX: 'auto', width: '100%', marginBottom: '15px' }} >
        <TabItem active={tab === 0} onClick={() => setTab(0)} >
          {t('ru.halls.tabs.info')}
        </TabItem>
        <TabItem active={tab === 1} onClick={() => setTab(1)} >
          {t('ru.halls.tabs.packets')}
        </TabItem>
        {change && <>
          <TabItem active={tab === 2} onClick={() => setTab(2)} >
            {t('ru.halls.tabs.tables')}
          </TabItem>
          {userRole && userRole !== 'cashier' && (
            <TabItem active={tab === 3} onClick={() => setTab(3)} >
              {t('ru.halls.tabs.jackpots')}
            </TabItem>
          )}
          {userRole && userRole !== 'cashier' && (
            <TabItem active={tab === 4} onClick={() => setTab(4)} >
              {t('ru.halls.tabs.binding')}
            </TabItem>
          )}
        </>}
        {findPermissionOr(items, ['updateHallMath', 'updateChildrenHallMath']) && <TabItem active={tab === 6} onClick={() => setTab(6)} >
          {'Математика'}
        </TabItem>}
        <TabItem active={tab === 7} onClick={() => setTab(7)} >
          {t('ru.halls.tabs.hotkeys')}
        </TabItem>
      </Box>{' '}
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
        {returnTab(tab)}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button fullWidth className={classes.submitBtn} variant="contained" onClick={cancelBtnEvent} >
              {cancelBtnText}
            </Button>
            <Button fullWidth disabled={validation} className={classes.submitBtn} variant="contained" type="submit" >
              {submitBtnText}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ErrorText error={error} />
        </Grid>
      </Grid>
      {isCity && <Overlay>
        <CityForm hideCity={hideCity} />
      </Overlay>}
      {isCompany && <Overlay>
        <CompanyForm hideCompany={hideCompany} />
      </Overlay>}
      {isCurrency && <Overlay>
        <CurrencyForm hideCurrency={hideCurrency} />
      </Overlay>}
    </FormWrapper>
  )
}

export default HallForm
